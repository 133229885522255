.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  position: relative;
  z-index: 2;
  
}
.AppOuter {
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 3;
}

.ParticlesOuter {
  
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
}

.LogoDiv {
  width: 80%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}